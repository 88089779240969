import { Controller } from "react-hook-form";
import FormField from "layouts/applications/wizard/components/FormField";
import { Autocomplete, Grid } from "@mui/material";
import CustomTextField from "components/Form/CustomTextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { NumericFormat } from "react-number-format";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import MDTypography from "components/MDTypography";
import ShiftFormSwitchController from "layouts/pages/jobs/components/ShiftJobs/ShiftForm/ShiftFormSwitchController";
import ShiftFormDayTimeController from "layouts/pages/jobs/components/ShiftJobs/ShiftForm/ShiftFormDayTimeController";
import isEmpty from "lodash/isEmpty";
import { useAppContextController } from "context/AppContext";
import { useEffect, useRef } from "react";
import isEqual from "lodash/isEqual";

const ShiftForm = ({ control, watch, setValue, getValues }) => {
  const switchData = [
    {
      label: "Clock-In at Shift Start",
      name: "additionalConfig.autoAdjustEarlyClockIn",
      disabled: watch("additionalConfig.earlyClockInMinutes") < 1,
    }, // autoAdjustEarlyClockIn mismatch
    { label: "Allow Employee to Edit Time", name: "additionalConfig.allowManualPunches" }, // allowManualPunches mismatch
    { label: "Geofence", name: "geofence" },
    { label: "Allow Personal Clockout", name: "additionalConfig.allowPersonnelClockout" }, // done
    {
      label: "Allow Scheduling Unaccrued PTO",
      name: "additionalConfig.allowUnaccruedLeaveRequest",
    }, // allowUnaccruedLeaveRequest mismatch
    { label: "Allow Auto Clockout", name: "additionalConfig.autoClockoutShiftEnd" }, // autoClockoutShiftEnd mismatch
    { label: "Allow Breaks", name: "additionalConfig.allowBreaks" }, // done
    { label: "Allow Negative Leave", name: "additionalConfig.allowNegativeLeave" }, // done
    { label: "Allow Overtime", name: "additionalConfig.allowOvertime" }, // done
  ];

  const days = [
    { label: "MON", day: "monday" },
    { label: "TUE", day: "tuesday" },
    { label: "WED", day: "wednesday" },
    { label: "THU", day: "thursday" },
    { label: "FRI", day: "friday" },
    { label: "SAT", day: "saturday" },
    { label: "SUN", day: "sunday" },
  ];

  const { currentJob, venues } = useAppContextController();

  const locationListRef = useRef([]);

  // initialize venues on dropdown field secondary venue and venue name list
  useEffect(() => {
    if (venues) {
      Object.keys(venues).forEach((key) => {
        if (isEqual(venues[key].name, currentJob?.venueName)) {
          locationListRef.current = venues[key].locations?.map((location) => ({
            ...location,
            label: location.locationName,
          }));
        }
      });
    }
  }, [venues, currentJob]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Controller
          name="shiftName"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <CustomTextField
              name="shiftName"
              onChange={(e) => setValue("shiftName", e.target.value)}
              label="Shift Name"
              {...field}
              showError
              errorMessage={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          name="startDate"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Start Date"
                value={!field.value ? null : field.value}
                onChange={(date) => {
                  field.onChange(new Date(date));
                }}
                renderInput={(params) => (
                  <CustomTextField {...params} showError errorMessage={error?.message} />
                )}
              />
            </LocalizationProvider>
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          name="endDate"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="End Date"
                value={!field.value ? null : field.value}
                onChange={(date) => {
                  field.onChange(new Date(date));
                }}
                renderInput={(params) => (
                  <CustomTextField {...params} showError errorMessage={error?.message} />
                )}
              />
            </LocalizationProvider>
          )}
        />
      </Grid>

      {/* <Grid item xs={12} sm={6}> */}
      {/*  <Controller */}
      {/*    name="position" */}
      {/*    control={control} */}
      {/*    render={({ field, fieldState: { error } }) => ( */}
      {/*      <CustomTextField */}
      {/*        onChange={(e) => setValue("position", e.target.value)} */}
      {/*        label="Position" */}
      {/*        {...field} */}
      {/*        showError */}
      {/*        errorMessage={error?.message} */}
      {/*      /> */}
      {/*    )} */}
      {/*  /> */}
      {/* </Grid> */}
      <Grid item xs={12} sm={6}>
        <Controller
          name="location"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              options={
                !isEmpty(locationListRef.current)
                  ? [{ label: "<Original Venue Location>" }, ...locationListRef.current]
                  : []
              }
              name="location"
              value={getValues("location") ? field.value : null}
              onChange={(e, data) => {
                field.onChange(data);
              }}
              renderInput={(params) => (
                <CustomTextField
                  type="text"
                  label="Location"
                  showError
                  errorMessage={error?.message}
                  {...params}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          name="payRate"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <NumericFormat
              InputProps={{
                startAdornment: "$",
                inputProps: {
                  inputMode: "numeric",
                },
              }}
              allowNegative={false}
              label="Pay Rate"
              decimalScale={2}
              {...field}
              customInput={CustomTextField}
              showError
              errorMessage={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          name="billRate"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <NumericFormat
              InputProps={{
                startAdornment: "$",
                inputProps: {
                  inputMode: "numeric",
                },
              }}
              allowNegative={false}
              label="Bill Rate"
              decimalScale={2}
              {...field}
              customInput={CustomTextField}
              showError
              errorMessage={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item sm={12}>
        <MDTypography variant="h5" color="info" mt={4} mb={2}>
          Additional Config
        </MDTypography>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Controller
              name="additionalConfig.earlyClockInMinutes"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <NumericFormat
                  InputProps={{
                    inputProps: {
                      inputMode: "numeric",
                    },
                  }}
                  allowNegative={false}
                  suffix=" Minutes"
                  label="Early ClockIn Minutes"
                  decimalScale={0}
                  {...field}
                  customInput={FormField}
                  showError
                  errorMessage={error?.message}
                />
              )}
            />
          </Grid>
          {switchData.map((item, index) => (
            <ShiftFormSwitchController
              key={`${item.name}_switch_${index.toString()}`}
              control={control}
              label={item.label}
              name={item.name}
              disabled={item.disabled}
            />
          ))}
          <Grid item sm={6}>
            <Controller
              name="additionalConfig.negativeHoursLimit"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <NumericFormat
                  InputProps={{
                    inputProps: {
                      inputMode: "numeric",
                    },
                  }}
                  allowNegative={false}
                  suffix=" Hours"
                  label="Negative Hours Limit"
                  decimalScale={0}
                  {...field}
                  customInput={FormField}
                  disabled={!watch("additionalConfig.allowNegativeLeave")}
                  showError
                  errorMessage={error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <MDTypography variant="h5" color="info" mt={3} mb={2}>
          Weekly Schedule
        </MDTypography>
        <Grid container spacing={1}>
          <Grid item sm={1.5}>
            <MDTypography variant="h6" color="info" my={0.5}>
              DAYS
            </MDTypography>
            <MDTypography variant="h6" color="info" my={0.5}>
              START
            </MDTypography>
            <MDTypography variant="h6" color="info" my={0.5}>
              END
            </MDTypography>
          </Grid>
          {days.map((item, index) => (
            <ShiftFormDayTimeController
              key={`${item.day}_day_${index.toString()}`}
              control={control}
              label={item.label}
              day={item.day}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ShiftForm;
