import { useCallback, useEffect, useState, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Icon,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  Button,
  Grid,
  Box,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { isEmpty, uniq } from "lodash";
import { CallMerge, Mail, DoDisturbAlt, AddCircleRounded } from "@mui/icons-material";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import DataTable from "components/DataTable";
import FiltersList from "components/FiltersList";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import OnboardingStatus from "components/OnboardingStatus";
import PictureAvatar from "components/PictureAvatar";
import Searchbar from "components/Searchbar";
import TagListSearch from "components/TagListSearch";
import VenueIcon from "components/VenueIcon";
import FlagIcon from "@mui/icons-material/Flag";
import BlockIcon from "@mui/icons-material/Block";

import VenueStatusButton from "components/VenueStatusButton";
import { useAppContextController } from "context/AppContext";
import useCompanyCache from "hooks/useCompanyCache";
import useVenueCache from "hooks/useVenueCache";
import moment from "moment";
import { SnackbarProvider, useSnackbar } from "notistack";
import useSearchbarStore from "stores/useSearchbarStore";
import { BulkMessagingModal } from "layouts/pages/events/components/BulkMessagingModal";
import { getApplicantVenueStatus } from "utils/helpers/applicant";
import useSort from "utils/useSort";
import fetchCustomers from "api/companies/fetchCustomers";
import applicantEnableMerge from "api/applicants/applicantEnableMerge";
import usePreferredPageSize from "hooks/usePreferredPageSize";
import ReportModal from "components/ReportModal";
import fetchApplicantsAdvanced from "../../actions/fetchApplicantsAdvanced";
import fetchApplicants from "../../actions/fetchApplicants";
import fetchApplicantsOnly from "../../actions/fetchApplicantsOnly";
import ApplicantActionModal from "../ApplicantActionModal";
import ApplicantsTableActions from "../ApplicantsTableActions";
import DuplicateApplicantModal from "../DuplicateApplicantModal";
import AdvancedSearchModal from "../AdvancedSearchModal";
import NoTagSelectedModal from "../NoTagSelectedModal";
import NoResultsModal from "../NoResultsModal";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    whiteSpace: "pre-line",
  },
});

const ApplicantsTable = ({
  fetchAll = false,
  title = "Title",
  filters,
  pageSize = 50,
  page,
  setPage,
  setFilters,
  showFiltersList = true,
  showSearchBar = true,
  navigateToAction = "",
  venueSlug = null,
  showEventStatus = false,
  setSelectedId,
  status = "Applicant;Partner",
  messageContext,
  useOrTags,
  setUseOrTags,
}) => {
  const {
    currentApplicant,
    setCurrentApplicant,
    currentVenue,
    venues,
    setVenues,
    userType,
    company,
    setCompany,
    setCompanyType,
    currentLoggedUser,
  } = useAppContextController();

  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });
  const { isLoadingVenues, refetchVenues } = useVenueCache({ venues, setVenues, company });
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const queryClient = useQueryClient();
  const [includeEmployees, setIncludeEmployees] = useState(false);
  const { searchResults, setSearchResults } = useSearchbarStore((state) => ({
    setSearchResults: state.setSearchResults,
    searchResults: state.searchResults,
  }));
  const [searchQuery, setSearchQuery] = useState(null);
  const { action, id } = useParams();
  const setNavigateUrl = useNavigate();
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [toastAlert, setToastAlert] = useState(false);
  const [isBulkMessageModalOpen, toggleBulkMessageModal] = useState(false);
  const [openNoTagModal, setOpenNoTagModal] = useState(false);
  const [openNoResultsModal, setOpenNoResultsModal] = useState(false);
  const [openReportMessageModal, setOpenReportMessageModal] = useState(false);
  const classes = useStyle();
  const [modalInfo, setModalInfo] = useState({ data: currentApplicant });
  // Pagination state
  const { limit, setLimit, pageLimitConfirmationModal } = usePreferredPageSize(pageSize);
  const [mergeApplicantData, setMergeApplicantData] = useState([]);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
  const [searchTagList, setSearchTagList] = useState([]);
  const { order, orderBy, toggleSort } = useSort("createdDate", "asc");
  const [selectedTags, setSelectedTags] = useState("");
  const [filterTagsApplied, setFilterTagsApplied] = useState(false);
  const [advancedSearchModalOpen, setAdvancedSearchModalOpen] = useState(false);

  const enableMergeMutation = useMutation(applicantEnableMerge);
  const { enqueueSnackbar } = useSnackbar();
  const [searchRefetchKey, setSearchRefetchKey] = useState(0);

  const refetchSearch = () => setSearchRefetchKey(Math.random());

  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const allOptions = {
    ...options,
    filters: { status, ...filters, ...selectedTags },
    useOrTags,
  };

  const isCompany = company?.companyType === "Company";

  const removeIgnoredFlag = (e, row) => {
    e.stopPropagation();
    const params = {
      id: row._id,
      agent: `${currentLoggedUser.firstName} ${currentLoggedUser.lastName}`,
      createAgent: currentLoggedUser._id,
    };
    enableMergeMutation.mutate(params, {
      onSuccess: (result) => {
        if (result && result.data.success) {
          enqueueSnackbar(`Ignore flag removed successfully`, { variant: "success" });
          refetchSearch();
        } else {
          enqueueSnackbar(`Something went wrong`, { variant: "error" });
        }
      },
      onError: () => {
        enqueueSnackbar(`Something went wrong`, { variant: "error" });
      },
    });
  };

  const {
    data: applicants,
    isLoading,
    refetch: refetchApplicants,
  } = useQuery(["applicants", allOptions, searchQuery], () => {
    if (searchQuery) {
      return fetchApplicantsAdvanced({ ...allOptions, searchQuery, includeEmployees });
    }
    return fetchApplicants(allOptions);
  });

  const {
    data: companies,
    isLoading: isLoadingCompanies,
    refetch: refetchCompanies,
  } = useQuery(
    ["employee_table_companies", { filters: { primaryCompany: false }, page: 1, limit: 0 }],
    () => fetchCustomers({ filters: { primaryCompany: false }, page: 1, limit: 0 }),
    {
      enabled: company?.companyType !== "Venue",
      select: (data) => data?.data ?? [],
    }
  );

  useEffect(() => {
    if (!venues || Object.keys(venues).length) refetchVenues();
    setSearchResults([]);
  }, [venues]);

  useEffect(() => {
    if (applicants?.data?.length && applicants.data?.length === 1) {
      //      setSelectedId(applicants.data[0]._id);
      setCurrentApplicant(applicants.data[0]);
    }
  }, [applicants?.data]);

  useEffect(() => {
    if (action && applicants?.data?.length) {
      const [setApplicantAction] = applicants?.data?.filter((item) => item?._id === id);
      setCurrentApplicant(setApplicantAction);
    }
  }, [action]);

  const formatSearchResults = (searchResult) => {
    const formatted = searchResult?.map((v, index) => {
      if (v.status === "Deleted") return v;
      if (v.doNotMerge === "Yes") return { ...v, mergeStatus: "ignore" };
      const result = searchResult.find(
        (val, idx) =>
          val.firstName === v.firstName &&
          val.lastName === v.lastName &&
          v._id !== val._id &&
          val.doNotMerge !== "Yes" &&
          val.status !== "Deleted"
      );
      if (result) {
        return { ...v, mergeStatus: "merge" };
      }
      return v;
    });
    return formatted;
  };

  const openMergeModal = useCallback(
    (row) => {
      const updatedRow = { ...row };
      const duplicateApplicants = searchResults.filter(
        (v) =>
          row.firstName === v.firstName &&
          row.lastName === v.lastName &&
          v._id !== row._id &&
          v.mergeStatus !== "ignore" &&
          v.status !== "Deleted"
      );
      updatedRow.mergeStatus = "primary";
      setMergeApplicantData([updatedRow, ...duplicateApplicants]);
      setDuplicateModalOpen(true);
    },
    [searchResults]
  );

  const columns = useMemo(
    () => [
      ...(company?.companyType === "Venue"
        ? [
            {
              title: "Avatar",
              field: "profileImg",
              customCell: (image, field, row) => (
                <PictureAvatar
                  image={row?.profileImg}
                  firstName={row?.firstName}
                  lastName={row?.lastName}
                  userId={row?._id}
                  size="md"
                />
              ),
            },
          ]
        : []),
      {
        title: "Flag",
        field: "recordFlagged",
        width: 15,
        customCell: (flagged, field, row) => {
          if (row.isDnu === "Yes")
            return (
              <Tooltip title="Do not use">
                <BlockIcon fontSize="large" color="error" />
              </Tooltip>
            );
          if (flagged === "Yes")
            return (
              <Tooltip title="Check Employee Notes">
                <FlagIcon fontSize="large" color="error" />
              </Tooltip>
            );
          return "";
        },
      },
      {
        title: "Onboard",
        field: "profileImg",
        customCell: (image, field, row) => <OnboardingStatus applicant={row} size="md" />,
        width: "10%",
      },
      {
        title: "Merge",
        field: "mergeStatus",
        width: "10%",
        customCell: (value, row) => {
          if (value === "merge") {
            return (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  openMergeModal(row);
                }}
              >
                <CallMerge fontSize="medium" color="info" />
              </IconButton>
            );
          }
          if (value === "ignore") {
            return (
              <IconButton
                onClick={(e) => {
                  removeIgnoredFlag(e, row);
                }}
              >
                <DoDisturbAlt fontSize="medium" color="error" />
              </IconButton>
            );
          }
          return "";
        },
      },
      { title: "Type", field: "status" },
      { title: "Applicant Status", field: "applicantStatus" },
      ...(venueSlug && currentVenue
        ? [
            {
              title: "Venue Status",
              field: "slug",
              customCell: (_id, field, row) => (
                <VenueStatusButton
                  status={getApplicantVenueStatus(row, venueSlug)}
                  venue={venues?.[venueSlug] || {}}
                  onUpdate={() => refetchApplicants()}
                  applicant={row}
                />
              ),
            },
          ]
        : []),

      {
        title: "Last Name",
        field: "lastName",
        customCell: (name, field, row) =>
          row ? (
            <CustomWidthTooltip
              title={`${row?.email} \n ${row?.city} ${row?.state}`}
              sx={{ color: "red" }}
            >
              <div>{name}</div>
            </CustomWidthTooltip>
          ) : null,
      },
      {
        title: "First Name",
        field: "firstName",
        customCell: (name, field, row) =>
          row ? (
            <CustomWidthTooltip
              title={`${row?.email} \n ${row?.city} ${row?.state}`}
              sx={{ color: "red" }}
            >
              <div>{name}</div>
            </CustomWidthTooltip>
          ) : null,
      },
      ...(filters.status !== "Partner"
        ? [
            // {
            //   title: "Birthdate",
            //   field: "birthDate",
            //   customCell: (date) => date ? moment(date).format("YYYY-MM-DD") : '',
            // },
            // {
            //   title: "Hire Date",
            //   field: "hireDate",
            //   customCell: (date) => date ? moment(date).format("YYYY-MM-DD") : '',
            // },
            ...(company?.companyType === "Venue"
              ? [
                  {
                    title: "Venue",
                    field: "venues",
                    customCell: (vens, field, row) => {
                      if (!vens || !vens?.length) return "";
                      const { venueSlug: vslug } = vens?.[0];
                      if (!venues) return "";
                      const venue = venues[vslug];
                      return (
                        <>
                          <VenueIcon
                            logoUrl={venue?.logoUrl}
                            slug={vslug}
                            name={venue?.name}
                            imageWidth="50"
                            imageHeight="50"
                          />
                        </>
                      );
                    },
                  },
                  { title: "Rank", field: "rank" },
                ]
              : [
                  {
                    title: "Comp Slug",
                    field: "companies",
                    customCell: (comps, field, row) => {
                      if (!comps || !comps?.length) return "";
                      const { companySlug: cslug } = comps?.[comps?.length - 1];
                      if (!cslug) return "";
                      return cslug?.toUpperCase();
                    },
                  },
                  {
                    title: "Customer",
                    field: "companies",
                    customCell: (comps, field, row) => {
                      if (!comps || !comps.length) return "";
                      let foundComp = comps?.find((c) => c.status === "On Assignment");
                      if (!foundComp) foundComp = comps?.[comps.length - 1];
                      const cslug = foundComp?.companySlug;
                      if (!cslug) return "";
                      // const venue = venues[cslug];
                      if (company?.companyType === "Company") {
                        const existingCompany = (companies ?? []).find(
                          (comp) => comp.slug === cslug
                        );
                        return (
                          <CompanyLogoSquare company={existingCompany} defaultCompany={company} />
                        );
                      }

                      return (
                        <span>
                          {cslug}
                          {/* <VenueIcon
                    logoUrl={venue?.logoUrl}
                    slug={vslug}
                    name={venue?.name}
                    imageWidth="50"
                    imageHeight="50"
                  /> */}
                        </span>
                      );
                    },
                  },
                ]),
            { title: "Phone", field: "phone" },
            {
              title: "Source",
              field: "referralSource",
              customCell: (source, field, row) => source || row?.systemSource || "",
            },
            {
              title: "Apply Date",
              field: "applicationDate",
              customCell: (appDate, field, row) => {
                const date = row.createdDate;
                return (
                  <Tooltip title={moment(date).format("YYYY-MM-DD hh:mm a")}>
                    <span>{moment(date).format("YYYY-MM-DD")}</span>
                  </Tooltip>
                );
              },
            },
          ]
        : [
            { title: "Partner", field: "partnerSlug" },
            { title: "Helm ID", field: "employeeID" },
          ]),
      {
        title: "Applicant Actions",
        field: "_id",
        customCell: (rowId, field, row) => (
          <ApplicantsTableActions
            id={rowId}
            row={row}
            setCurrentApplicant={setCurrentApplicant}
            setModalInfo={setModalInfo}
            setPage={setPage}
            setNavigateUrl={setNavigateUrl}
            setGenericModalOpen={setGenericModalOpen}
            company={company}
          />
        ),
      },
    ],
    [companies, company, currentVenue, filters.status, venueSlug, venues]
  );

  const searchColumns = [
    {
      title: "Onboard",
      field: "profileImg",
      customCell: (field, row) => <OnboardingStatus applicant={row} size="md" />,
      width: "10%",
    },
    {
      title: "Flag",
      field: "recordFlagged",
      width: 15,
      customCell: (flagged, row) => {
        if (row.isDnu === "Yes")
          return (
            <Tooltip title="Do not use">
              <BlockIcon fontSize="medium" color="error" />
            </Tooltip>
          );
        if (flagged === "Yes")
          return (
            <Tooltip title="Check Employee Notes">
              <FlagIcon fontSize="medium" color="error" />
            </Tooltip>
          );
        return "";
      },
    },
    {
      title: "Merge",
      field: "mergeStatus",
      width: "10%",
      customCell: (value, row) => {
        if (value === "merge") {
          return (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                openMergeModal(row);
              }}
            >
              <CallMerge fontSize="medium" color="info" />
            </IconButton>
          );
        }
        if (value === "ignore") {
          return (
            <IconButton
              onClick={(e) => {
                removeIgnoredFlag(e, row);
              }}
            >
              <DoDisturbAlt fontSize="medium" color="error" />
            </IconButton>
          );
        }
        return "";
      },
    },
    { title: "Type", field: "status" },
    { title: "Applicant Status", field: "applicantStatus" },
    ...(venueSlug && currentVenue
      ? [
          {
            title: "Venue Status",
            field: "slug",
            customCell: (_id, field, row) => (
              <VenueStatusButton
                status={getApplicantVenueStatus(row, venueSlug)}
                venue={venues?.[venueSlug] || {}}
                onUpdate={() => refetchApplicants()}
                applicant={row}
              />
            ),
          },
        ]
      : []),

    {
      title: "Last Name",
      field: "lastName",
      customCell: (name, row) =>
        row ? (
          <CustomWidthTooltip
            title={`${row?.email} \n ${row?.city} ${row?.state}`}
            sx={{ color: "red" }}
          >
            <div>{name}</div>
          </CustomWidthTooltip>
        ) : null,
    },
    {
      title: "First Name",
      field: "firstName",
      customCell: (name, row) =>
        row ? (
          <CustomWidthTooltip
            title={`${row?.email} \n ${row?.city} ${row?.state}`}
            sx={{ color: "red" }}
          >
            <div>{name}</div>
          </CustomWidthTooltip>
        ) : null,
    },
    { title: "Phone", field: "phone" },
    ...(filters.status !== "Partner" && company?.companyType === "Venue"
      ? [
          // {
          //   title: "Birthdate",
          //   field: "birthDate",
          //   customCell: (date) => date ? moment(date).format("YYYY-MM-DD") : '',
          // },
          // {
          //   title: "Hire Date",
          //   field: "hireDate",
          //   customCell: (date) => date ? moment(date).format("YYYY-MM-DD") : '',
          // },
          {
            title: "Venue",
            field: "venues",
            customCell: (vens, field, row) => {
              if (!vens || !vens?.length) return "";
              const { venueSlug: vslug } = vens?.[0];
              if (!venues) return "";
              const venue = venues[vslug];
              return (
                <>
                  <VenueIcon
                    logoUrl={venue?.logoUrl}
                    slug={vslug}
                    name={venue?.name}
                    imageWidth="50"
                    imageHeight="50"
                  />
                </>
              );
            },
          },
          { title: "Rank", field: "rank" },
          {
            title: "Source",
            field: "referralSource",
            customCell: (source, field, row) => source || row?.systemSource || "",
          },
          {
            title: "Apply Date",
            field: "createdDate",
            customCell: (date) => (date ? moment(date).format("YYYY-MM-DD") : ""),
          },
        ]
      : [
          { title: "Partner", field: "partnerSlug" },
          { title: "Helm ID", field: "employeeID" },
        ]),
  ];

  const handleRowClick = (applicant, e) => {
    e.stopPropagation();
    setCurrentApplicant({ ...applicant });
    setNavigateUrl(
      `/applicants/${applicant._id}${navigateToAction ? `/action/${navigateToAction}` : ""}`
    );
  };

  const addNew = (e) => {
    if (!isEmpty(currentApplicant)) {
      setCurrentApplicant({});
    }
    setNavigateUrl("/applicants/create");
    e.stopPropagation();
  };

  const clearTagsHandler = () => {
    const tempFilters = filters;
    delete tempFilters?.tags;
    setFilters(tempFilters);
    setSearchTagList([]);
    setUseOrTags(true);
  };
  const allTagsHandler = () => setUseOrTags(false);
  const anyTagsHandler = () => setUseOrTags(true);

  const duplicateHandler = () => {
    setDuplicateModalOpen(true);
  };
  const sortLastFirst = (a, b) => {
    const o1 = a.lastName.toLowerCase();
    const o2 = b.lastName.toLowerCase();

    const p1 = a.firstName.toLowerCase();
    const p2 = b.firstName.toLowerCase();

    if (o1 < o2) return -1;
    if (o1 > o2) return 1;
    if (p1 < p2) return -1;
    if (p1 > p2) return 1;
    return 0;
  };

  return (
    <>
      <Card className={classes.box}>
        <SnackbarProvider
          maxSnack={3}
          open={toastAlert}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setToastAlert(false)}
          key="top-center"
        >
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="space-between"
            py={1}
            pr={2}
          >
            <Grid item>
              <MDBox display="flex">
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="4rem"
                  height="4rem"
                  variant="gradient"
                  bgColor="error"
                  color="white"
                  borderRadius="xl"
                  ml={3}
                  mt={-6}
                >
                  <Icon fontSize="large">people</Icon>
                </MDBox>
                <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
                  {title}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item display="flex" alignItems="center">
              {searchTagList?.length > 0 && (
                <ToggleButtonGroup value={useOrTags ? "or" : "all"} color="secondary" exclusive>
                  <ToggleButton value="or" onClick={anyTagsHandler}>
                    Any
                  </ToggleButton>
                  <ToggleButton value="all" onClick={allTagsHandler}>
                    All
                  </ToggleButton>
                  <ToggleButton value="clear" onClick={clearTagsHandler}>
                    Clear
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
              {!isSmallScreen && (
                <TagListSearch
                  searchTagList={searchTagList}
                  setSearchTagList={setSearchTagList}
                  filters={filters}
                  setFilters={setFilters}
                />
              )}
              {showSearchBar && (
                <Searchbar
                  fetch={fetchApplicantsOnly}
                  fetchAll={false}
                  placeholder="Search Applicants"
                  columns={searchColumns}
                  queryCharacterLimit={3}
                  resultsLimit={15}
                  setFilters={setFilters}
                  filters={filters}
                  setPage={setPage}
                  searchBy={["firstName", "lastName", "email", "altEmail"]}
                  filterBy="_id"
                  scrollLimit={200}
                  sortFunction={sortLastFirst}
                  onRowClick={(item) => {
                    if (item?._id) setSelectedId(item._id);
                    setNavigateUrl(`/applicants/${item._id}/action/applicantinfo`);
                  }}
                  formatData={formatSearchResults}
                  refetchDependency={searchRefetchKey}
                />
              )}
              {isCompany && (
                <>
                  <MDBox sx={{ width: "25rem" }}>
                    <Button
                      variant="contained"
                      fullWidth
                      style={{ color: "white" }}
                      onClick={() => setAdvancedSearchModalOpen(true)}
                    >
                      Advanced Search
                    </Button>
                  </MDBox>
                  {/* <IconButton color="info" onClick={addNew} sx={{ fontSize: 40 }}>
                    <AddCircleRounded />
                  </IconButton> */}
                </>
              )}
              <Tooltip title="Bulk Message">
                <IconButton
                  color="error"
                  onClick={() => {
                    if (searchTagList?.length > 0 && applicants?.data?.length > 0) {
                      toggleBulkMessageModal(true);
                    } else if (searchTagList?.length === 0) {
                      setOpenNoTagModal(true);
                    } else if (
                      applicants?.data?.length === 0 ||
                      applicants?.data?.length === undefined
                    ) {
                      setOpenNoResultsModal(true);
                    }
                  }}
                >
                  <Mail fontSize="large" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Create Report">
                <IconButton color="info" onClick={() => setOpenReportMessageModal(true)}>
                  <ViewComfyIcon fontSize="large" />
                </IconButton>
              </Tooltip>
              {isCompany && (
                <IconButton color="info" onClick={addNew} sx={{ fontSize: 40 }}>
                  <AddCircleRounded />
                </IconButton>
              )}
            </Grid>
          </Grid>

          <MDBox ml={3} flexWrap="wrap">
            {showFiltersList && (
              <FiltersList
                filters={filters}
                setFilters={setFilters}
                searchTagList={searchTagList}
                setSearchTagList={setSearchTagList}
                clearTagsHandler={clearTagsHandler}
              />
            )}
          </MDBox>
          <MDBox pt={2} pb={2}>
            <DataTable
              columns={columns.filter((v) => v.field !== "mergeStatus")}
              data={applicants}
              page={page}
              limit={limit}
              setPage={setPage}
              setLimit={setLimit}
              isLoading={isLoading}
              onRowClick={(row, e) => handleRowClick(row, e)}
              order={order}
              orderBy={orderBy}
              toggleSort={toggleSort}
              fetchAll={fetchAll}
              defaultSort="createdDate"
              idField="_id"
            />
          </MDBox>
          <ApplicantActionModal
            open={genericModalOpen}
            setOpen={setGenericModalOpen}
            modalInfo={modalInfo}
            setToastAlert={setToastAlert}
            currentApplicant={currentApplicant}
            messageContext={messageContext}
          />
        </SnackbarProvider>
        {duplicateModalOpen && (
          <DuplicateApplicantModal
            open={duplicateModalOpen}
            setOpen={setDuplicateModalOpen}
            mergeData={mergeApplicantData}
            parentComponent="Applicant"
            onSubmit={refetchSearch}
          />
        )}
        <AdvancedSearchModal
          advancedSearchModalOpen={advancedSearchModalOpen}
          setAdvancedSearchModalOpen={setAdvancedSearchModalOpen}
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          setFilters={setFilters}
          filters={filters}
          useOrTags={useOrTags}
          setUseOrTags={setUseOrTags}
          showFiltersList
          parent="Applicants"
          setPage={setPage}
          includeEmployees={includeEmployees}
          setIncludeEmployees={setIncludeEmployees}
        />
      </Card>
      {isBulkMessageModalOpen && (
        <BulkMessagingModal
          recipientText={filters?.status?.search(";") !== -1 ? "All" : filters?.status}
          applicants={applicants?.data?.map((item) => ({ ...item, id: item?._id })) || []}
          isOpen={isBulkMessageModalOpen}
          toggleModal={toggleBulkMessageModal}
          messageContext="Applicant"
        />
      )}
      {openNoTagModal && <NoTagSelectedModal open={openNoTagModal} setOpen={setOpenNoTagModal} />}
      {openNoResultsModal && (
        <NoResultsModal open={openNoResultsModal} setOpen={setOpenNoResultsModal} />
      )}
      {openReportMessageModal && (
        <ReportModal
          message="Coming Soon!"
          isOpen={openReportMessageModal}
          toggleOpen={setOpenReportMessageModal}
        />
      )}
      {pageLimitConfirmationModal}
    </>
  );
};

// Setting default values for the props
ApplicantsTable.defaultProps = {
  // currentVenue: {},
  fetchAll: false,
  title: "Applicants",
  filters: null,
  showFiltersList: true,
};

export default ApplicantsTable;
