import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Card, Grid, Icon, IconButton, Box } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { FileDownload, Mail, Lock, LockOpen, Handshake } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import DataTable from "components/DataTable";
import FiltersList from "components/FiltersList";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PictureAvatar from "components/PictureAvatar";
import Searchbar from "components/Searchbar";
import StaffingPoolExportModal from "components/StaffingPoolExportModal";
import VenueStatusButton from "components/VenueStatusButton";
import { useAppContextController } from "context/AppContext";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import ApplicantActionModal from "layouts/pages/applicants/components/ApplicantActionModal";
import ApplicantsFilterCards from "layouts/pages/applicants/components/ApplicantFilterCards";
import fetchStaffingPool from "layouts/pages/employees/actions/fetchStaffingPool";
import StaffingPoolFilterCards from "layouts/pages/employees/components/StaffingPoolFilterCards";
import { BulkMessagingModal } from "layouts/pages/events/components/BulkMessagingModal";
import VenuesApplicantsTableActions from "layouts/pages/jobs/components/JobsApplicantsTableActions";
import moment from "moment";
import { SnackbarProvider } from "notistack";
import { getApplicantVenueStatus } from "utils/helpers/applicant";
import useSort from "utils/useSort";
import TagSearchAndToggle from "components/TagSearchAndToggle";
import { isEmpty } from "lodash";
import OnboardingStatus from "components/OnboardingStatus";
import fetchPartners from "layouts/pages/partners/actions/fetchPartners";
import TableAction from "components/TableAction";
import EventPartnerActionModal from "layouts/pages/events/components/EventPartnerActionModal";
import PartnerIcon from "components/PartnerIcon";
import VenueSearch from "../VenueSearch";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    whiteSpace: "pre-line",
  },
});

const VenuesApplicantsTable = ({
  type = "Staffing",
  fetchAll = false,
  title = "Venue Applicants",
  filters,
  pageSize = 5,
  setFilters,
  showFiltersList = true,
  showSearchBar = true,
  navigateToAction = "",
  venueSlug = null,
  jobSlug = null,
  showEventStatus = false,
  setSelectedId,
  staffingPoolName,
}) => {
  const queryClient = useQueryClient();
  const { action, id } = useParams();
  const setNavigateUrl = useNavigate();
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [toastAlert, setToastAlert] = useState(false);
  const classes = useStyle();
  const { currentApplicant, setCurrentApplicant, currentVenue, venues, userType } =
    useAppContextController();
  const [modalInfo, setModalInfo] = useState({ data: currentApplicant });
  const [venueId, setVenueId] = useState(null);
  const [isBulkMessageModalOpen, toggleBulkMessageModal] = useState(false);
  const [isStaffingPoolModalOpen, toggleStaffingPoolModalOpen] = useState(false);

  const [partnerGenericModalOpen, setPartnerGenericModalOpen] = useState(false);
  const [partnerModalInfo, setPartnerModalInfo] = useState({ data: {} });

  const isNotClient = userType !== "Client" && userType !== "Event Admin";
  const isEventAdmin = userType === "Event Admin";

  const defaultFilters = {
    status: type === "Staffing" ? "Employee;Partner" : "Applicant",
  };
  if (type !== "Staffing") {
    defaultFilters.applicantStatus = "New";
  } else {
    defaultFilters.employmentStatus = "Active;Inactive;Terminated";
    defaultFilters["venues.status"] = "StaffingPool;Pending;Locked";
  }

  const [applicantsFilters, setApplicantsFilters] = useState(defaultFilters);
  const [candidates, setCandidates] = useState({});
  const [searchTagList, setSearchTagList] = useState([]);
  const [useOrTags, setUseOrTags] = useState(true);
  const [recipientText, setRecipientText] = useState("All");

  const isPartners = applicantsFilters?.status === "Partner";

  // Pagination state
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(pageSize); // Default pageSize higher than 10. Don't use preferred pageSize

  const clearTagsHandler = () => {
    const tempFilters = filters;
    delete tempFilters.tags;
    setApplicantsFilters(tempFilters);
    setSearchTagList([]);
    setUseOrTags(true);
  };

  const { order, orderBy, toggleSort } = useSort();
  const options = fetchAll ? { fetchAll, order, orderBy } : { page, limit, order, orderBy };
  const allOptions = {
    ...options,
    includeVerified: type === "Staffing",
    filters: {
      status: type === "Staffing" ? "Employee" : "Applicant",
      ...filters,
      ...applicantsFilters,
    },
    useOrTags,
    useElemMatch: type === "Staffing",
  };

  const {
    data: applicants,
    isLoading,
    refetch: refetchApplicants,
  } = useQuery(["venuesapplicants", allOptions], () =>
    type === "Applicants" ? fetchApplicants(allOptions) : fetchStaffingPool(allOptions)
  );

  // PARTNERS
  const partnerOptions = { page, limit, order, orderBy, useElemMatch: true };
  const allPartnerOptions = {
    ...partnerOptions,
    filters: { "venues.venueSlug": currentVenue?.slug },
  };
  const {
    data: partners,
    isLoading: partnersLoading,
    isRefetchingPartners,
    refetch: requeryPartners,
  } = useQuery(["venuePartners", allPartnerOptions], () => fetchPartners(allPartnerOptions), {
    onSuccess: (data) => {},
    enabled: isPartners,
  });
  // ----------------------

  useEffect(() => {
    if (applicants?.data?.length && applicants.data.length === 1) {
      // setSelectedId(applicants.data[0]._id);
      setCurrentApplicant(applicants.data[0]);
    }
  }, [applicants?.data]);

  useEffect(() => {
    if (action && applicants?.data?.length) {
      const [setApplicantAction] = applicants?.data?.filter((item) => item?._id === id);
      setCurrentApplicant(setApplicantAction);
    }
  }, [action, id, applicants?.data]);

  useEffect(() => {
    if (venueSlug && venues) {
      setVenueId(venues?.[venueSlug]?._id);
    }
  }, [venueSlug, venues]);

  const columns = [
    {
      title: "Avatar",
      field: "profileImg",
      customCell: (image, field, row) => (
        <PictureAvatar
          image={row?.profileImg}
          firstName={row?.firstName}
          lastName={row?.lastName}
          userId={row?.userRecordId ? row.userRecordId : row?._id}
          size="md"
        />
      ),
    },
    {
      title: "Locked",
      field: "recordLocked",
      customCell: (recordLocked) => (
        <Box pl={0.75}>
          {recordLocked === "Yes" ? (
            <Lock fontSize="large" color="error" />
          ) : (
            <LockOpen fontSize="large" color="success" />
          )}
        </Box>
      ),
    },
    ...(type !== "Staffing"
      ? [
          {
            title: "Onboard",
            field: "profileImg",
            customCell: (image, field, row) => <OnboardingStatus applicant={row} size="md" />,
          },
        ]
      : []),
    { title: "Type", field: "status" },
    ...(type !== "Staffing" ? [{ title: "Applicant Status", field: "applicantStatus" }] : []),
    ...(type === "Staffing" ? [{ title: "Employment Status", field: "employmentStatus" }] : []),
    ...(venueSlug && currentVenue
      ? [
          {
            title: "Venue Status",
            field: "slug",
            customCell: (_id, field, row) => (
              <>
                <VenueStatusButton
                  status={getApplicantVenueStatus(row, venueSlug)}
                  venue={currentVenue}
                  onUpdate={() => refetchApplicants()}
                  applicant={row}
                />
              </>
            ),
          },
        ]
      : []),
    {
      title: "Last Name",
      field: "lastName",
      customCell: (name, field, row) =>
        row ? (
          <CustomWidthTooltip
            title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
            sx={{ color: "red" }}
          >
            <div>{name}</div>
          </CustomWidthTooltip>
        ) : null,
    },
    {
      title: "First Name",
      field: "firstName",
      customCell: (name, field, row) =>
        row ? (
          <CustomWidthTooltip
            title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
            sx={{ color: "red" }}
          >
            <div>{name}</div>
          </CustomWidthTooltip>
        ) : null,
    },
    ...(type === "Staffing" ? [{ title: "Login", field: "loginVerified" }] : []),
    // {
    //   title: "Hire Date",
    //   field: "hireDate",
    //   customCell: (date) => moment(date).format("YYYY-MM-DD"),
    // },
    // { title: "Rep", field: "" },
    { title: "Phone", field: "phone" },
    { title: "Email", field: "email" },
    ...(isNotClient ? [{ title: "Rank", field: "rank" }] : []),
    { title: "Source", field: "referralSource" },
    {
      title: "Date",
      field: "createdDate",
      customCell: (date) => (date ? moment(date).format("YYYY-MM-DD") : ""),
    },
    ...(isNotClient || isEventAdmin
      ? [
          {
            title: "Applicant Actions",
            field: "_id",
            customCell: (rowId, field, row) => (
              <VenuesApplicantsTableActions
                type={type}
                id={rowId}
                row={row}
                setCurrentApplicant={setCurrentApplicant}
                setModalInfo={setModalInfo}
                setNavigateUrl={setNavigateUrl}
                setGenericModalOpen={setGenericModalOpen}
                parentTable="venues"
              />
            ),
          },
        ]
      : []),
  ];

  const columnsPartners = [
    {
      title: "Avatar",
      field: "partnerLogo",
      width: 120,
      customCell: (image, field, row) => (
        <PartnerIcon
          logoUrl={row?.partnerLogo}
          slug={row?.slug}
          name={row?.name}
          goToEnabled={false}
        />
      ),
    },
    {
      title: "Type",
      field: "status",
      width: 120,
      customCell: (image, field, row) => <Handshake fontSize="large" color="primary" />,
    },
    ...(venueSlug && currentVenue
      ? [
          {
            title: "Venue Status",
            field: "slug",
            width: 120,
            customCell: (_id, field, row) => (
              <>
                <VenueStatusButton
                  status={getApplicantVenueStatus(row, venueSlug)}
                  venue={currentVenue}
                  onUpdate={() => requeryPartners()}
                  applicant={row}
                  isPartner
                />
              </>
            ),
          },
        ]
      : []),
    {
      title: "Organization Name",
      field: "name",
    },
    {
      title: "Leader's Phone",
      field: "leader",
      customCell: (leader, field, row) => <>{leader?.phone}</>,
    },
    {
      title: "Leader's Email",
      field: "leader",
      customCell: (leader, field, row) => <>{leader?.email}</>,
    },
    ...(userType !== "Client" && userType !== "Event Admin"
      ? [
          {
            title: "Partner Actions",
            field: "_id",
            customCell: (rowId, field, row) => (
              <span>
                <TableAction
                  color="primary"
                  icon="mail"
                  tooltip={
                    !!row?.leader?.email || !!row?.leader?.phone
                      ? `Send a Message to ${row?.leader?.email ?? row?.leader?.phone}`
                      : "No leader available"
                  }
                  clickAction={(e) => {
                    setPartnerModalInfo({ type: "partnerMail", data: row });
                    setPartnerGenericModalOpen(true);
                  }}
                  disabled={!row?.leader?.email && !row?.leader?.phone}
                />
              </span>
            ),
          },
        ]
      : []),
  ];

  const handleRowClick = (applicant, e) => {
    e.stopPropagation();
    if (isPartners) {
      // setNavigateUrl(`/partners/${applicant.slug}?backTo=venues`);
    } else {
      setCurrentApplicant({ ...applicant });
      setNavigateUrl(
        type === "Staffing"
          ? `/employees/${applicant._id}${
              navigateToAction ? `/action/${navigateToAction}?backTo=venues` : "?backTo=venues"
            }`
          : `/applicants/${applicant._id}${
              navigateToAction ? `/action/${navigateToAction}?backTo=venues` : "?backTo=venues"
            }`
      );
    }
  };

  const addNew = (e) => {
    if (!currentApplicant) {
      setCurrentApplicant({});
    }
    setNavigateUrl("/applicants/create");
    e.stopPropagation();
  };

  const sortLastFirst = (a, b) => {
    const o1 = a.lastName.toLowerCase();
    const o2 = b.lastName.toLowerCase();

    const p1 = a.firstName.toLowerCase();
    const p2 = b.firstName.toLowerCase();

    if (o1 < o2) return -1;
    if (o1 > o2) return 1;
    if (p1 < p2) return -1;
    if (p1 > p2) return 1;
    return 0;
  };

  useEffect(() => {
    if (!applicants) return;
    setCandidates(applicants);
  }, [applicants]);

  useEffect(() => {
    if (applicantsFilters?.["licenses.licenseType.value"])
      setRecipientText(`Licenses: ${applicantsFilters?.["licenses.licenseType.value"]}`);
    if (applicantsFilters?.status) {
      if (applicantsFilters?.status?.includes("Applicant")) setRecipientText("All");
      else setRecipientText(applicantsFilters?.status);
    }
  }, [applicantsFilters]);

  return (
    <>
      <Card className={classes.box}>
        <SnackbarProvider
          maxSnack={3}
          open={toastAlert}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setToastAlert(false)}
          key="top-center"
        >
          <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
            <Grid item>
              <MDBox display="flex">
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="4rem"
                  height="4rem"
                  variant="gradient"
                  bgColor={type === "Staffing" ? "error" : "warning"}
                  color="white"
                  borderRadius="xl"
                  ml={3}
                  mt={-4}
                >
                  <Icon fontSize="large">people</Icon>
                </MDBox>
                <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
                  {title}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item display="flex" alignItems="center">
              <TagSearchAndToggle
                filters={applicantsFilters}
                setFilters={setApplicantsFilters}
                searchTagList={searchTagList}
                setSearchTagList={setSearchTagList}
                useOrTags={useOrTags}
                setUseOrTags={setUseOrTags}
              />
              {showSearchBar && (
                <Searchbar
                  fetch={fetchApplicants}
                  fetchAll={false}
                  placeholder="Search Applicants"
                  columns={columns.slice(1, columns.length - 1)}
                  queryCharacterLimit={3}
                  resultsLimit={15}
                  setFilters={setFilters}
                  setPage={setPage}
                  searchBy={["firstName", "lastName", "email", "altEmail"]}
                  filterBy="_id"
                  scrollLimit={200}
                  sortFunction={sortLastFirst}
                  // onRowClick={(item) => {
                  //   if (item?._id) setSelectedId(item._id);
                  // }}
                />
              )}
              <MDBox>
                {isNotClient && (
                  <Tooltip title="Bulk Message">
                    <IconButton
                      disabled={isPartners}
                      color="error"
                      onClick={() => toggleBulkMessageModal(true)}
                    >
                      <Mail fontSize="large" />
                    </IconButton>
                  </Tooltip>
                )}
                {currentVenue?.slug && (
                  <Tooltip title={`Export ${staffingPoolName} Staffing Pool`}>
                    <IconButton
                      disabled={isPartners}
                      color="info"
                      onClick={() => toggleStaffingPoolModalOpen(true)}
                    >
                      <FileDownload />
                    </IconButton>
                  </Tooltip>
                )}
                {isNotClient && (
                  <Tooltip title="Create a New Employee">
                    <IconButton className={classes.addButton} color="info" onClick={addNew}>
                      <AddCircleRoundedIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </MDBox>
            </Grid>
          </Grid>
          {showFiltersList && !isEmpty(searchTagList) && (
            <MDBox ml={3} mb={2}>
              <Grid item xs={6}>
                <FiltersList
                  filters={applicantsFilters}
                  setFilters={setApplicantsFilters}
                  searchTagList={searchTagList}
                  setSearchTagList={setSearchTagList}
                  clearTagsHandler={clearTagsHandler}
                  onlyDisplayTags
                />
              </Grid>
            </MDBox>
          )}
          <MDBox ml={3}>
            <MDBox display="flex" flexWrap="wrap" alignItems="center" rowGap="1rem">
              {type === "Staffing" ? (
                <StaffingPoolFilterCards
                  setPage={setPage}
                  parent="Venues"
                  setFilters={setApplicantsFilters}
                  filters={{ ...filters, ...applicantsFilters }}
                  useElemMatch
                  doReplace
                  baseFilters={{
                    employmentStatus: "Active;Inactive;Terminated",
                    status: "Employee;Partner",
                    "venues.status": "StaffingPool;Pending;Locked",
                    ...filters,
                  }}
                />
              ) : (
                <ApplicantsFilterCards
                  parent="Venues"
                  setFilters={setApplicantsFilters}
                  filters={{ ...filters, ...applicantsFilters }}
                />
              )}
              {!isPartners && (
                <VenueSearch
                  data={candidates}
                  setData={setCandidates}
                  fetchedData={applicants}
                  setPage={setPage}
                  applicantsFilters={applicantsFilters}
                  label={type === "Applicants" ? "Applicants" : "Staff"}
                />
              )}
            </MDBox>
          </MDBox>
          <MDBox pt={2} pb={2}>
            <DataTable
              columns={isPartners ? columnsPartners : columns}
              data={isPartners ? partners : candidates}
              page={page}
              limit={limit}
              setPage={setPage}
              setLimit={setLimit}
              isLoading={isPartners ? partnersLoading : isLoading}
              onRowClick={isNotClient ? (row, e) => handleRowClick(row, e) : () => {}}
              order={order}
              orderBy="lastName:asc,firstName:asc"
              toggleSort={toggleSort}
              fetchAll={isPartners ? false : fetchAll}
              defaultSort="lastName:asc,firstName:asc"
              idField="_id"
            />
          </MDBox>
          <ApplicantActionModal
            open={genericModalOpen}
            setOpen={setGenericModalOpen}
            modalInfo={modalInfo}
            setToastAlert={setToastAlert}
            currentApplicant={currentApplicant}
            messageContext="Venue"
            venueId={venueId}
          />
          <EventPartnerActionModal
            open={partnerGenericModalOpen}
            setOpen={setPartnerGenericModalOpen}
            modalInfo={partnerModalInfo}
            setModalInfo={setPartnerModalInfo}
            setToastAlert={setToastAlert}
            toggleReadApps={requeryPartners}
          />
        </SnackbarProvider>
      </Card>
      {isBulkMessageModalOpen && (
        <BulkMessagingModal
          recipientText={recipientText}
          applicants={applicants?.data?.map((item) => ({ ...item, id: item._id })) || []}
          isOpen={isBulkMessageModalOpen}
          toggleModal={toggleBulkMessageModal}
          messageContext="Venue"
          attachmentContext="Venue"
          venueId={venueId}
          venueSlug={venueSlug}
        />
      )}
      {isStaffingPoolModalOpen && (
        <StaffingPoolExportModal
          venueSlug={currentVenue?.slug}
          isOpen={isStaffingPoolModalOpen}
          toggleOpen={toggleStaffingPoolModalOpen}
          filters={allOptions.filters}
          useElemMatch={type === "Staffing"}
          useOrTags
        />
      )}
    </>
  );
};

// Setting default values for the props
VenuesApplicantsTable.defaultProps = {
  // currentVenue: {},
  fetchAll: false,
  title: "Applicants",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
VenuesApplicantsTable.propTypes = {
  // currentVenue: PropTypes.object,
  fetchAll: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default VenuesApplicantsTable;
