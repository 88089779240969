import { Controller, useForm } from "react-hook-form";
import { Autocomplete, Box, Button, Checkbox, Grid, Stack, Typography } from "@mui/material";
import CustomModal from "components/CustomizedMui/CustomModal";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import SaveIcon from '@mui/icons-material/Save';
import DownloadIcon from '@mui/icons-material/Download';
import FormField from "layouts/applications/wizard/components/FormField";
import chart from "assets/images/chart.png";
import FiltersList from "components/FiltersList";
import { useMemo, useState } from "react";
import { useAppContextController } from "context/AppContext";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import VenueIcon from "components/VenueIcon";
import DataTable from "components/DataTable";
import FilterEditorModal from "./FilterEditorModal";

const ReportModal = ({ toggleOpen, isOpen, message, type = "Staffing", }) => {

    const { company, currentJob, setCurrentJob, venues } = useAppContextController();
    const [customerData, setCustomerData] = useState({});
    const [filterEditorModalOpen, setFilterEditorModalOpen] = useState(false)
    const isCompany = company?.companyType === "Company";

    const defaultFilters = {
        status: type === "Staffing" ? "Employee" : "Applicant",
    };
    if (type !== "Staffing") defaultFilters.applicantStatus = "New";

    const [applicantsFilters, setApplicantsFilters] = useState(defaultFilters);

    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        watch,
        reset,
        trigger,
        setError,
        formState: { errors, isDirty, isValid, isSubmitSuccessful },
    } = useForm();

    const hardcodedData = {
        count: 5,
        data: [
            {
                logoUrl: "https://images.stadiumpeople.com/sp/allenisd/venues/logo/allenisd-venuelogo.png",
                venueSlug: "allenisd",
                venueName: "Allen ISD",
                title: "Event Staff",
                postedDate: "2023-10-27T21:38:16.312Z",
                new: 34,
                atc: 82,
                screened: 30,
                preHire: 12,
            },
            {
                logoUrl: "https://images.stadiumpeople.com/sp/auburn/venues/logo/auburn-venuelogo.png",
                venueSlug: "auburn",
                venueName: "Auburn University",
                title: "Event Staff",
                postedDate: "2023-10-27T21:38:16.312Z",
                new: 34,
                atc: 82,
                screened: 30,
                preHire: 12,
            },
            {
                logoUrl: "https://images.stadiumpeople.com/sp/cutx/venues/logo/cutx-venuelogo.png",
                venueSlug: "cutx",
                venueName: "Credit Union of Texas Event Center",
                title: "Event Staff",
                postedDate: "2023-10-27T21:38:16.312Z",
                new: 34,
                atc: 82,
                screened: 30,
                preHire: 12,
            },
            {
                logoUrl: "https://images.stadiumpeople.com/sp/dcm/venues/logo/dcm-venuelogo.png",
                venueSlug: "dcm",
                venueName: "Dallas Cowboys Merchandising",
                title: "Event Staff",
                postedDate: "2023-10-27T21:38:16.312Z",
                new: 34,
                atc: 82,
                screened: 30,
                preHire: 12,
            },
            {
                logoUrl: "https://images.stadiumpeople.com/sp/emory/venues/logo/Emory-venuelogo.png",
                venueSlug: "emory",
                venueName: "Emory",
                title: "Event Staff",
                postedDate: "2023-10-27T21:38:16.312Z",
                new: 34,
                atc: 82,
                screened: 30,
                preHire: 12,
            }]
    }

    const columns = useMemo(
        () => [
            {
                title: "",
                field: "logoUrl",
                customCell: (logoUrl, field, row) => {
                    if (isCompany) {
                        const rowCompany = customerData[row.companySlug] ?? {};
                        return <CompanyLogoSquare company={rowCompany} defaultCompany={company} />;
                    }
                    return <VenueIcon logoUrl={logoUrl} slug={row?.venueSlug} name={row?.venueName} />;
                },
            },
            ...(!isCompany
                ? [
                    {
                        title: "Venue Name",
                        field: "venueSlug",
                        customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
                    },
                ]
                : []),
            { title: "Job Posted Date", field: "postedDate" },
            {
                title: "new",
                field: "new",

            },
            {
                title: "atc",
                field: "atc",

            },
            {
                title: "screened",
                field: "screened",

            },
            {
                title: "pre-hire",
                field: "preHire",

            },

        ],
        [
            isCompany,
            customerData,
            company,
        ]
    );

    return (
        <CustomModal
            isOpen={isOpen}
            onClose={() => toggleOpen(false)}
            cardSx={{ maxWidth: "70%", "max-height": "90%", overflowY: "scroll", padding: 2 }}
        >
            <FlexWrapper rowGap="20px">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Box border="1px solid grey" height="100%">
                            <Grid container spacing={2} p={3} >
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        Options
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name="dateField"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    label="Date Field"
                                                    renderInput={(params) => <FormField {...params} />}
                                                    {...field}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    />
                                    <Controller
                                        name="sortBy"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <Autocomplete
                                                options={["Column Name", "other option"]}
                                                renderInput={(params) => <FormField label="Sort By"{...params} />}
                                                {...field}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="order"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <Autocomplete
                                                options={["DESC", "ASC"]}
                                                renderInput={(params) => <FormField label="Order"{...params} />}
                                                {...field}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="filterCards"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <Autocomplete
                                                options={["option1", "option2"]}
                                                renderInput={(params) => <FormField label="Filter Cards"{...params} />}
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <Controller
                                        name="dateRange"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <Autocomplete
                                                options={["Today", "2 weeks"]}
                                                renderInput={(params) => <FormField label="Date Range"{...params} />}
                                                {...field}
                                            />
                                        )}
                                    />
                                    <Box border="solid 1px gray">
                                        <Typography variant="body2">
                                            Columns
                                        </Typography>
                                        <Stack display="flex" direction="row" alignItems="center">
                                            <Controller
                                                name="firstName"
                                                control={control}
                                                defaultValue={false}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        id="firstName"
                                                        checked={field.value}
                                                    />)}
                                            />
                                            <Typography fontWeight="bold" fontSize="1rem">
                                                First Name
                                            </Typography>
                                        </Stack>
                                        <Stack display="flex" direction="row" alignItems="center">
                                            <Controller
                                                name="lastName"
                                                control={control}
                                                defaultValue={false}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        id="lastName"
                                                        checked={field.value}
                                                    />)}
                                            />
                                            <Typography fontWeight="bold" fontSize="1rem">
                                                Last Name
                                            </Typography>
                                        </Stack>
                                        <Stack display="flex" direction="row" alignItems="center">
                                            <Controller
                                                name="status"
                                                control={control}
                                                defaultValue={false}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        id="status"
                                                        checked={field.value}
                                                    />)}
                                            />
                                            <Typography fontWeight="bold" fontSize="1rem">
                                                Status
                                            </Typography>
                                        </Stack>
                                        <Stack display="flex" direction="row" alignItems="center">
                                            <Controller
                                                name="source"
                                                control={control}
                                                defaultValue={false}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        id="source"
                                                        checked={field.value}
                                                    />)}
                                            />
                                            <Typography fontWeight="bold" fontSize="1rem">
                                                Source
                                            </Typography>
                                        </Stack>

                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <Button variant="contained" fullWidth onClick={() => { setFilterEditorModalOpen(true) }}>
                                        Filter Editor
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <Button variant="contained" fullWidth >
                                        Generate Report
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box border="1px solid grey" height="100%">
                            <Grid container spacing={2} p={3} >
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        Chart
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>

                                    <Controller
                                        name="xAxis"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <Autocomplete
                                                options={["Months", "other option"]}
                                                renderInput={(params) => <FormField label="X-Axis"{...params} />}
                                                {...field}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="chartType"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <Autocomplete
                                                options={["Bar Graph", "other option"]}
                                                renderInput={(params) => <FormField label="Chart Type"{...params} />}
                                                {...field}
                                            />
                                        )}
                                    />

                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <Controller
                                        name="yAxis"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <Autocomplete
                                                options={["Numbers", "other option"]}
                                                renderInput={(params) => <FormField label="Y-Axis"{...params} />}
                                                {...field}
                                            />
                                        )}
                                    />
                                    <Box mt={1}>
                                        <Button variant="contained" fullWidth >
                                            Generate Chart
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box
                                        component="img"
                                        src={chart}
                                        alt="chart"
                                        sx={{
                                            width: '100%',
                                            height: 'auto',
                                            objectFit: 'contain',
                                            display: 'block',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <Button variant="contained" fullWidth startIcon={<LocalPrintshopIcon />}>
                                        Print
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <Button variant="contained" fullWidth startIcon={<DownloadIcon />}>
                                        Export
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>

                    </Grid>
                    <Grid item xs={12} >
                        <FiltersList
                            filters={applicantsFilters}
                        // setFilters={setApplicantsFilters}
                        // searchTagList={searchTagList}
                        // setSearchTagList={setSearchTagList}
                        // clearTagsHandler={clearTagsHandler}
                        // onlyDisplayTags
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <Box border="1px solid grey">
                            <Typography variant="h5">
                                Table
                            </Typography>
                            <DataTable
                                columns={columns}
                                data={hardcodedData}
                            />
                        </Box>

                    </Grid>
                    <Grid item sm={4} />
                    <Grid item sm={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} >
                                <Button variant="contained" fullWidth startIcon={<SaveIcon />}>
                                    Save Report
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4} >
                                <Button variant="contained" fullWidth startIcon={<LocalPrintshopIcon />}>
                                    Print
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4} >
                                <Button variant="contained" fullWidth startIcon={<DownloadIcon />}>
                                    Export
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </FlexWrapper >
            {filterEditorModalOpen && <FilterEditorModal open={filterEditorModalOpen} setOpen={setFilterEditorModalOpen} />}
        </CustomModal >
    );
};

export default ReportModal;
