import { useState, useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Button, Typography, Grid, IconButton, styled } from "@mui/material";
import GenericModal from "components/GenericModal";
import parse from "html-react-parser";
import CancelIcon from "@mui/icons-material/Cancel";
import DataList from "components/DataList";
import moment from "moment";
import { useAppContextController } from "context/AppContext";
import FormField from "layouts/applications/wizard/components/FormField";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import { useMutation, useQueryClient } from "react-query";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
import AddANote from "components/AddANote";
import useDropdowns from "hooks/useDropdowns";
import postNote from "layouts/pages/applicants/actions/postNote";
import { useSnackbar } from "notistack";
import CreateEditNoteModal from "../CreateEditNoteModal";

const MembersNotesList = ({ open, setOpen, currentApplicant }) => {
  const queryClient = useQueryClient();

  const { currentLoggedUser } = useAppContextController();

  const { dropdowns: noteTypes } = useDropdowns({ entity: "noteTypes" });
  const { enqueueSnackbar } = useSnackbar();

  const updateApplicantMutation = useMutation(updateApplicant, {
    onError: (err) => {
      enqueueSnackbar(`Something went wrong! ${err}`, { variant: "error" });
    },
    onSuccess: async (_, { data }) => {
      enqueueSnackbar("Notes Have Been Successfully Updated", { variant: "success" });
      queryClient.invalidateQueries(["partnersapplicants"]);
      queryClient.invalidateQueries(["members"]);
    },
  });

  const postNoteMutation = useMutation(postNote, {
    onError: (err) => {
      enqueueSnackbar(`Something went wrong! ${err}`, { variant: "error" });
    },
    onSuccess: async (_, { data }) => {
      enqueueSnackbar("Notes Have Been Successfully Updated", { variant: "success" });
      queryClient.invalidateQueries(["partnersapplicants"]);
      queryClient.invalidateQueries(["members"]);
    },
  });

  const saveChanges = (notes) => {
    const update = async () => {
      let res;
      return updateApplicantMutation.mutateAsync({
        applicantId: currentApplicant?._id,
        data: { notes },
      });
    };
    update();
  };

  const saveNote = (noteRec) => {
    const newNote = {
      type: noteRec.type,
      text: noteRec.text,
      firstName: currentLoggedUser?.firstName,
      lastName: currentLoggedUser?.lastName,
      userId: currentLoggedUser?._id,
      date: new Date().toISOString(),
    };

    const updateNewNote = async () => {
      let res;
      return postNoteMutation.mutateAsync({ applicantId: currentApplicant?._id, data: newNote });
    };

    return updateNewNote();
  };

  const header = (
    <MDTypography
      variant="h5"
      color="dark"
      sx={{ mt: 2, mb: 1, ml: 2 }}
      key={`text_addNote_${currentApplicant?._id}`}
    >
      Add a Note For: {currentApplicant?.firstName} {currentApplicant?.lastName}
    </MDTypography>
  );

  const modalBody = (
    <AddANote
      noteTypes={noteTypes}
      setOpen={setOpen}
      saveNote={saveNote}
      saveChanges={saveChanges}
      applicant={currentApplicant}
      allowEmpty={false}
      showCurrentNotes
      showAll
    />
  );

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        body={modalBody}
        header={header}
        sx={(theme) => ({
          width: "90% !important",
          maxWidth: "1100px !important",
          [theme.breakpoints.down("md")]: {
            "& .MuiCardContent-root": {
              padding: "0px",
            },
          },
        })}
      />
    </>
  );
};

const CloseEditorButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "0%",
  right: "0%",
  m: 1,
  p: 0,
}));

export default MembersNotesList;
