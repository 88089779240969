import { useState } from "react";
import { Box, Button, IconButton, Stack, Switch, Table, TableCell, TableRow, Typography, Icon, Autocomplete, CardActions } from "@mui/material";
import GenericModal from "components/GenericModal";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { filterEditorFormSchema } from "data/filterEditor";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FormField from "layouts/applications/wizard/components/FormField";


const defaultValues = {
    andOr: "And",
    filterEditorModalRows: [{
        field: null,
        isIsNot: null,
        operator: null,
        value: null

    }],
};

const FilterEditorModal = ({ open, setOpen }) => {

    const resolver = useYupValidationResolver(filterEditorFormSchema);

    const {
        control,
        setValue,
        handleSubmit,
        watch,
        register,
        getValues,
        reset,
        setError,
        clearErrors,
        formState: { isDirty, errors },
    } = useForm({ defaultValues, resolver });

    const { fields, append, update, remove } = useFieldArray({
        control,
        name: 'filterEditorModalRows',
    });
    const addRow = () => {
        append({
            field: null,
            isIsNot: null,
            operator: null,
            value: null
        })

    }
    const handleReportFilters = (data) => {
        console.log("data", data)
        setOpen(false)
    };

    const modalBody = (<>
        <form onSubmit={handleSubmit(handleReportFilters)}>
            <Stack display="flex" direction="row" alignItems="center">
                <Typography variant="h6">
                    And
                </Typography>
                <Controller
                    name="andOr"
                    control={control}
                    render={({ field }) => (
                        <Switch
                            checked={field.value === "And"}
                            onChange={(e) => field.onChange(e.target.checked ? "And" : "Or")}

                        />
                    )}
                />
                <Typography variant="h6">
                    OR
                </Typography>
            </Stack>
            <Box mb={3} style={{ height: "21rem", overflow: "auto" }}>
                <Table
                    sx={(theme) => ({
                        [theme.breakpoints.down("desktop")]: {
                            display: "block",
                            overflowX: "auto",
                            whiteSpace: "nowrap",
                            width: "max-content"
                        },
                    })}>
                    <TableRow
                        key="modal_filter_titles"
                        sx={({ typography: { size, fontWeightBold } }) => ({
                            td: {
                                fontSize: size.xs,
                                fontWeight: fontWeightBold,
                                textTransform: "uppercase",
                            },
                        })}
                    >
                        <TableCell width="40%">
                            Field
                        </TableCell>
                        <TableCell width="10%"   >
                            Not
                        </TableCell>
                        <TableCell width="20%"    >
                            Operator
                        </TableCell>
                        <TableCell width="25%" >
                            Value
                        </TableCell>
                        <TableCell width="5%" />
                    </TableRow>
                    {fields?.map((field, index) => {
                        return <TableRow key={field.id} >
                            <TableCell   >
                                <Controller
                                    name={`filterEditorModalRows.${index}.field`}
                                    control={control}
                                    render={({ field: cellField, fieldState: { error } }) => (
                                        <Autocomplete
                                            {...cellField}
                                            options={["Status", "other"] || []}
                                            onChange={(e, v) => cellField.onChange(v)}
                                            renderInput={(params) => (
                                                <FormField
                                                    {...params}
                                                    placeholder="Select Field"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    )}
                                />
                                <Typography color="error" fontSize="0.75rem">{errors?.[`filterEditorModalRows[${index}].field`]?.message}</Typography>
                            </TableCell>
                            <TableCell   >
                                <Autocomplete
                                    {...register(`filterEditorModalRows.${index}.isIsNot`, { required: "Is or Not is required" })}
                                    options={["Not", "Is"] || []}
                                    onChange={(e, v) => {
                                        setValue(`filterEditorModalRows.${index}.isIsNot`, v)
                                    }}
                                    renderInput={(params) => <FormField variant="outlined"{...params} placeholder="Select" type="text" />}
                                />
                                <Typography color="error" fontSize="0.75rem">{errors?.[`filterEditorModalRows[${index}].isIsNot`]?.message}</Typography>
                            </TableCell>
                            <TableCell  >
                                <Autocomplete
                                    {...register(`filterEditorModalRows.${index}.operator`, { required: "Operator is required" })}
                                    options={["equal", "Grater Than Equal To", "less Than"] || []}
                                    onChange={(e, v) => {
                                        setValue(`filterEditorModalRows.${index}.operator`, v)
                                    }}
                                    renderInput={(params) => <FormField variant="outlined"{...params} placeholder="Select Operator" type="text" />}
                                />
                                <Typography color="error" fontSize="0.75rem">{errors?.[`filterEditorModalRows[${index}].operator`]?.message}</Typography>
                            </TableCell>
                            <TableCell   >
                                <Autocomplete
                                    {...register(`filterEditorModalRows.${index}.rowValue`, { required: "Value is required" })}
                                    options={["Inactive", "10", "2024-07-15"] || []}
                                    onChange={(e, v) => {
                                        setValue(`filterEditorModalRows.${index}.rowValue`, v)
                                    }}
                                    renderInput={(params) => <FormField variant="outlined"{...params} placeholder="Input value" type="text" />}
                                />
                                <Typography color="error" fontSize="0.75rem">{errors?.[`filterEditorModalRows[${index}].rowValue`]?.message}</Typography>
                            </TableCell>
                            <TableCell  >
                                <IconButton
                                    sx={{ p: 0 }}
                                    onClick={() => { remove(index) }}
                                >
                                    <DeleteForeverIcon color="error" fontSize="medium" />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    })}

                </Table>
                {errors?.missingValue && (
                    <Typography color="error">
                        {errors?.missingValue.message}
                    </Typography>
                )}
                <Button variant="text" onClick={() => { addRow() }}>
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add Condition
                </Button>
            </Box>
            <Typography color="error" fontSize="0.75rem">{errors?.filterEditorModalRows?.message}</Typography>

            <CardActions sx={{
                display: "flex",
                justifyContent: "end"
            }}>
                <Button variant="contained" color="secondary" onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button variant="contained" type="submit">
                    Apply Filter
                </Button>
            </CardActions>
        </form>
    </>
    );



    const modalTitle = (
        <Typography
            variant="h5"

            sx={{ mt: 2, mb: 1, ml: 2 }}
        // key={`note_title_${modalInfo.data.id}`}
        >
            Filter Editor
        </Typography>
    );


    return (
        <>
            {open && (
                <GenericModal
                    open={open}
                    setOpen={setOpen}
                    body={modalBody}
                    header={modalTitle}
                    width="60%"
                />
            )}
        </>
    );
};

export default FilterEditorModal;
