import * as yup from "yup";

export const shiftsInfoSchema = yup.object().shape({
  shiftName: yup.string().required("Shift Name is required"),
  startDate: yup.string().required("Start Date is required"),
  endDate: yup.string().required("End Date is required"),
  payRate: yup
    .number()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .nullable()
    .required("Pay Rate is required")
    .typeError("Pay Rate must be a number"),

  billRate: yup
    .number()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .nullable()
    .required("Bill Rate is required")
    .typeError("Bill Rate must be a number"),
  // defaultSchedule: yup.object().shape({
  //   monday: yup.object().shape({
  //     start: yup.string().required("Start Time is required"),
  //     end: yup.string().required("End Time is required"),
  //   }),
  //   tuesday: yup.object().shape({
  //     start: yup.string().required("Start Time is required"),
  //     end: yup.string().required("End Time is required"),
  //   }),
  //   wednesday: yup.object().shape({
  //     start: yup.string().required("Start Time is required"),
  //     end: yup.string().required("End Time is required"),
  //   }),
  //   thursday: yup.object().shape({
  //     start: yup.string().required("Start Time is required"),
  //     end: yup.string().required("End Time is required"),
  //   }),
  //   friday: yup.object().shape({
  //     start: yup.string().required("Start Time is required"),
  //     end: yup.string().required("End Time is required"),
  //   }),
  //   saturday: yup.object().shape({
  //     start: yup.string().required("Start Time is required"),
  //     end: yup.string().required("End Time is required"),
  //   }),
  //   sunday: yup.object().shape({
  //     start: yup.string().required("Start Time is required"),
  //     end: yup.string().required("End Time is required"),
  //   }),
  // }),
  // additionalConfig: yup.object().shape({
  //   earlyClockInMinutes: yup.number().required("Early Clock In Minutes is required"),
  //   autoAdjustEarlyClockIn: yup.boolean().required("Auto Adjust Early Clock In is required"),
  //   allowManualPunches: yup.boolean().required("Allow Manual Punches is required"),
  //   allowOvertime: yup.boolean().required("Allow Overtime is required"),
  //   allowBreaks: yup.boolean().required("Allow Breaks is required"),
  //   allowPersonnelClockout: yup.boolean().required("Allow Personnel Clockout is required"),
  //   allowUnaccruedLeaveRequest: yup.boolean().required("Allow Unaccrued Leave Request is required"),
  //   allowNegativeLeave: yup.boolean().required("Allow Negative Leave is required"),
  //   negativeHoursLimit: yup.number
  //     .required("Negative Hours Limit is required")
  //     .min(0, "Negative Hours Limit must be greater than 0"),
  //   autoClockoutShiftEnd: yup.boolean().required("Auto Clockout Shift End is required"),
  // }),
});
