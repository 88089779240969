import { useEffect, useMemo, useState } from "react";
import { useAppContextController } from "context/AppContext";
import { useForm } from "react-hook-form";
import ShiftJobsItem from "layouts/pages/jobs/components/ShiftJobsItem";
import CustomCard from "components/CustomCard";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PanelActions from "components/PanelActions";
import { cardStyles as styles, customPanelCard } from "layouts/pages/customers/styles";
import { Stack } from "@mui/material";
import MDBox from "components/MDBox";
import DataListContainer from "components/DataListContainer";
import ShiftForm from "layouts/pages/jobs/components/ShiftJobs/ShiftForm";
import ConfirmPanelChanges from "components/ConfirmPanelChanges";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { shiftsInfoSchema } from "data/shiftInfoSchema";
import MDTypography from "components/MDTypography";
import StaffingPool from "layouts/pages/jobs/components/ShiftJobs/StaffingPool";
import { useMutation, useQueryClient } from "react-query";
import updateJob from "layouts/pages/jobs/actions/updateJob";
import updateCompanyJob from "layouts/pages/companyjobs/actions/updateJob";
import { useSnackbar } from "notistack";

const emptyValues = {
  shiftName: "",
  startDate: "",
  endDate: "",
  // position: "",
  location: "",
  payRate: "",
  billRate: "",
  defaultSchedule: {
    monday: { start: "", end: "" },
    tuesday: { start: "", end: "" },
    wednesday: { start: "", end: "" },
    thursday: { start: "", end: "" },
    friday: { start: "", end: "" },
    saturday: { start: "", end: "" },
    sunday: { start: "", end: "" },
  },
  additionalConfig: {
    geofence: false,
    earlyClockInMinutes: 0, // done
    autoAdjustEarlyClockIn: false, // done
    allowManualPunches: false, // done
    allowOvertime: false, // done
    allowBreaks: false, // done
    allowPersonnelClockout: false, // done
    allowUnaccruedLeaveRequest: false, // done
    allowNegativeLeave: false, // done
    negativeHoursLimit: 4,
    autoClockoutShiftEnd: false, // done
  },
  exceptions: [],
  shiftRoster: [],
};

const ShiftJobs = ({ isVenue = false }) => {
  const [shifts, setShifts] = useState([]);
  const [selectedShiftIndex, setSelectedShiftIndex] = useState(null);
  const [isShiftsDirty, setShiftsDirty] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const {
    currentJob,
    setCurrentJob,
    isMobile,
    setCurrentFormDirty,
    isCurrentFormDirty,
    setPanelChangesOpen,
    setChangePanel,
    changePanel,
  } = useAppContextController();

  useEffect(() => {
    if (currentJob?.shifts) {
      setShifts(currentJob.shifts);
    }
  }, [currentJob?.shifts]);

  console.log("shifts", shifts);

  const resolver = useYupValidationResolver(shiftsInfoSchema);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    trigger,
    getValues,
    formState: { isDirty, dirtyFields, errors },
  } = useForm({
    resolver,
  });

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: updateJobMutation } = useMutation(isVenue ? updateJob : updateCompanyJob);
  const idName = isVenue ? "_id" : "jobId";

  const handleUpdateJob = (updatedValues) => {
    updateJobMutation(
      { [idName]: currentJob?._id, data: updatedValues },
      {
        onSuccess: async (res) => {
          await queryClient.invalidateQueries("company_jobs_table");
          setCurrentJob({ ...currentJob, ...updatedValues });
          enqueueSnackbar("Job has been updated.", { variant: "success" });
          setShiftsDirty(false);
          setCurrentFormDirty(false);
          if (selectedShiftIndex !== null) {
            reset(updatedValues?.shifts[selectedShiftIndex]);
          } else if (showDetails) {
            setSelectedShiftIndex(updatedValues?.shifts.length - 1);
            reset(updatedValues?.shifts[updatedValues?.shifts.length - 1]);
          } else {
            reset(emptyValues);
          }
        },
        onError: (err) => console.log(err),
      }
    );
  };

  console.log(isDirty, dirtyFields, errors);

  const handleShiftSave = (data) => {
    console.log("data", data);
    const modifiedData = { ...data };
    modifiedData.startDate = new Date(data.startDate).toISOString();
    modifiedData.endDate = new Date(data.endDate).toISOString();

    // if shift already exists, update it
    if (selectedShiftIndex !== null) {
      const updatedShifts = [...shifts];
      updatedShifts[selectedShiftIndex] = modifiedData;
      handleUpdateJob({ shifts: updatedShifts });
      return;
    }

    // create new shift
    handleUpdateJob({ shifts: [...shifts, modifiedData] });
  };

  // shows form details and adjusts current shift on add new shift or shift row select
  const addNewShift = () => {
    reset(emptyValues);
    setSelectedShiftIndex(null);
    setShowDetails(true);
  };

  const handleRemoveShift = (idx) => {
    const updatedShifts = shifts.filter((_, i) => i !== idx);
    // setShifts(updatedShifts);
    // setShiftsDirty(true);
    if (idx === selectedShiftIndex) {
      setSelectedShiftIndex(null);
      setShowDetails(false);
    }
    handleUpdateJob({ shifts: updatedShifts });
  };

  const handleConfirmChanges = async () => {
    const isValid = await trigger();
    if (isValid) {
      handleSubmit(handleShiftSave)();
      changePanel();
      setCurrentFormDirty(false);
    }
  };

  const handleCancelChanges = () => {
    reset(emptyValues);
    setShiftsDirty(false);
  };

  // to enable discard changes modal when switching panels
  useEffect(() => {
    setCurrentFormDirty(isDirty || isShiftsDirty);
  }, [isDirty, isShiftsDirty]);

  const selectShift = (index, row) => {
    setSelectedShiftIndex(index);
    setShowDetails(true);
    reset(row);
  };

  const renderAdditionalShifts = (row, idx) => (
    <ShiftJobsItem
      row={row}
      idx={idx}
      selectShift={selectShift}
      onRemoveShift={handleRemoveShift}
    />
  );

  const defaultStaffingPool = useMemo(() => {
    return shifts[selectedShiftIndex]?.shiftRoster ?? [];
  }, [selectedShiftIndex]);

  return (
    <form onSubmit={handleSubmit(handleShiftSave)}>
      <CustomCard
        icon={<PendingActionsIcon color="white" />}
        cardTitle="Job Shift Settings"
        // cardActions={
        //   isDirty || isShiftsDirty ? (
        //     <PanelActions onCancel={handleCancelChanges} />
        //   ) : (
        //     <ShiftJobsPanelActions
        //       onExpandCalender={() => console.log("Top be implemented")}
        //       onCopy={() => console.log("TOn be ")}
        //     />
        //   )
        // }
        cardActions={(isDirty || isShiftsDirty) && <PanelActions onCancel={handleCancelChanges} />}
        {...customPanelCard(isMobile, "success")}
      >
        <Stack spacing={4} sx={styles.container}>
          <MDBox>
            <DataListContainer
              title="Shifts"
              onAdd={addNewShift}
              data={shifts}
              renderRow={renderAdditionalShifts}
              payRate="Shifts"
              tableHeight={265}
              height={267}
              selected={selectedShiftIndex}
            />
          </MDBox>
          {showDetails && (
            <MDBox>
              <ShiftForm
                control={control}
                watch={watch}
                setValue={setValue}
                getValues={getValues}
              />
              <MDTypography
                component="label"
                variant="button"
                fontWeight="regular"
                color="secondary"
                sx={{ fontSize: "0.74rem" }}
              >
                Staffing Pool
              </MDTypography>
              <StaffingPool defaultValues={defaultStaffingPool} setValue={setValue} />
            </MDBox>
          )}
        </Stack>
      </CustomCard>
      <ConfirmPanelChanges onConfirm={handleConfirmChanges} />
    </form>
  );
};

export default ShiftJobs;
