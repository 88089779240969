import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import findIndex from "lodash/findIndex";

// @mui material components
import Grid from "@mui/material/Grid";
import { useAppContextController } from "context/AppContext";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PropTypes from "prop-types";

import useCompanyCache from "hooks/useCompanyCache";
import useVenueCache from "hooks/useVenueCache";
import fetchJobs from "layouts/pages/jobs/actions/fetchJobs";
import JobsApplicantsTable from "layouts/pages/jobs/components/JobsApplicantsTable";
import JobsEventsTable from "layouts/pages/jobs/components/JobsEventsTable";
import JobsFilterCards, { initialCards } from "layouts/pages/jobs/components/JobsFilterCards";
import JobsInformation from "layouts/pages/jobs/components/JobsInformation";
import JobsTable from "layouts/pages/jobs/components/JobsTable";

import { Alert, Snackbar } from "@mui/material";
import JobsModal from "layouts/pages/jobs/components/JobsModal";
import { isEmpty } from "lodash";
import useSessionAuth from "hooks/useSessionAuth";

import JobsReportPanel from "components/JobsReportPanel";
import ShiftJobs from "layouts/pages/jobs/components/ShiftJobs";
import updateJob from "./actions/updateJob";
import AddANoteModal from "../applicants/components/AddANoteModal";
import JobsNotes from "../applicants/components/ApplicantNotes";
import JobsSettings from "../companyjobs/components/CompanyJobsSettings";
import JobsActivities from "./components/JobsActivities";
import JobsContent from "./components/JobsContent";
import JobsAttachments from "./components/JobsAttachments/JobsAttachments";

function Jobs({ mode = "edit" }) {
  const navigate = useNavigate();

  const defaultFilterCardIndex = findIndex(initialCards, (card) => card.default);

  const {
    currentJob,
    setCurrentJob,
    currentLoggedUser,
    venues,
    setVenues,
    userType,
    isMobile,
    currentApplicant,
    company,
    setCompany,
    setCompanyType,
  } = useAppContextController();
  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });
  const isCompany = company?.companyType === "Company";
  const { isLoadingVenues, refetchVenues } = useVenueCache({ venues, setVenues, company });

  const { jobSlug, action } = useParams();
  const [open, setOpen] = useState(false);
  const [jobPreview, setJobPreview] = useState(null);
  const [actionSelected, setActionSelected] = useState(action || "");
  const [filters, setFilters] = useState({});
  const [applicantsFilters, setApplicantsFilters] = useState({});
  const [selectedPanel, setSelectedPanel] = useState("activities");
  const [refetchJobs, setRefetchJobs] = useState(false);
  const [page, setPage] = useState(1);
  const [notesModalOpen, setNoteModalOpen] = useState(false);

  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });

  const renderPanel = (selection) => {
    switch (selection) {
      case "activities":
        return <JobsActivities />;
      case "content":
        return <JobsContent />;
      case "attachments":
        return <JobsAttachments />;
      // case "status":
      //   return <CompanyJobsStatus />;
      case "settings":
        return <JobsSettings isVenue />;
      case "jobShiftSettings":
        return <ShiftJobs isVenue />;
      case "notes":
        return <JobsNotes setOpen={setNoteModalOpen} currentApplicant={currentJob} />;
      case "reportdata":
        // might have to use a component with JobsReportPanel inside depending on report panel update
        return <JobsReportPanel name={currentJob?.title} />;
      default:
        return null;
    }
  };

  const { mutateAsync: updateJobMutation } = useMutation(updateJob);
  const saveNote = (noteRec) => {
    const newNote = {
      type: noteRec.type,
      text: noteRec.text,
      firstName: currentLoggedUser?.firstName,
      lastName: currentLoggedUser?.lastName,
      userId: currentLoggedUser?._id,
    };

    const allNotes = currentJob?.notes || [];
    const notes = [...allNotes, newNote];

    const updateNewNote = async () => {
      let res;
      updateJobMutation(
        { _id: currentJob?._id, data: { notes } },
        {
          onSuccess: async (response) => {
            res = response;
            setCurrentJob({ ...currentJob, notes });
            setToastAlert({
              isShow: true,
              message: "Notes Have Been Successfully Updated",
              status: "success",
            });
          },
          onError: (err) => {
            res = err;
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        }
      );

      return res;
    };

    return updateNewNote();
  };

  // const deleteJobHandler = async (_id, jobTitle) => {
  //   const res = await deleteJob({ _id });

  //   if (res?.status === 200) {
  //     await queryClient.invalidateQueries("jobs");

  //     setToastAlert({
  //       isShow: true,
  //       message: `Job ${jobTitle} has been deleted`,
  //       status: "success",
  //     });
  //     return { success: true };
  //   }

  //   setToastAlert({
  //     isShow: true,
  //     message: `Something went wrong -- ${res?.data?.message}`,
  //     status: "error",
  //   });
  //   return { success: false, message: res?.data?.message };
  // };

  const { logoutAuth0User } = useSessionAuth();

  useEffect(() => {
    if ((defaultFilterCardIndex >= 0 && isEmpty(filters)) || (!jobSlug && filters.jobSlug)) {
      const { filterField, filterValue } = initialCards[defaultFilterCardIndex];
      setFilters({ [filterField]: filterValue });
    }
  }, [filters, jobSlug]);

  useEffect(() => {
    const getJob = async () => {
      const jobs = await fetchJobs({ filters: { jobSlug }, venues });
      let job;
      if (jobs && jobs?.data?.length) {
        job = jobs?.data[0];
        setCurrentJob(job);
        if (action === "jobpreview" && job) {
          setJobPreview();
          setOpen(true);
        }
      }
    };

    if (jobSlug && !filters.jobSlug && action) {
      setFilters({ jobSlug });
    }

    if (action !== actionSelected) {
      setActionSelected(action || "");
    }

    if (jobSlug && (!currentJob || currentJob.jobSlug !== jobSlug)) {
      try {
        getJob();
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    } else if (action === "jobpreview" && currentJob) {
      setJobPreview(currentJob);
      setOpen(true);
    }
  }, [jobSlug, action, actionSelected, venues]);

  const setNavigateUrl = (url) => navigate(url);

  return (
    <DashboardLayout stickyNavbar>
      <DashboardNavbar />
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          // severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12}>
          <JobsFilterCards
            setFilters={setFilters}
            setPage={setPage}
            initialIndex={defaultFilterCardIndex}
          />
          {/* <JobsShiftFilterCards */}
          {/*  setFilters={setFilters} */}
          {/*  setPage={setPage} */}
          {/*  initialIndex={defaultFilterCardIndex} */}
          {/* /> */}
          <JobsTable
            jobSlug={jobSlug}
            fetchAll
            filters={filters}
            setFilters={setFilters}
            actionSelected={actionSelected}
            setNavigateUrl={setNavigateUrl}
            showFiltersList
            title="Jobs"
            page={page}
            setPage={setPage}
            refetchJobs={refetchJobs}
            setOpen={setOpen}
            setJobPreview={setJobPreview}
            parent="jobs"
          />
        </Grid>
        {["jobinfo", "joball", ""].includes(actionSelected) && (jobSlug || mode === "create") && (
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <JobsInformation
                  mode={mode}
                  setRefetchJobs={setRefetchJobs}
                  setOpen={setOpen}
                  setJobPreview={setJobPreview}
                  setToastAlert={setToastAlert}
                  selectedPanel={selectedPanel}
                  onPanelSelect={setSelectedPanel}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                {mode === "edit" && renderPanel(selectedPanel)}
              </Grid>
            </Grid>
          </Grid>
        )}
        {["jobevents", "joball"].includes(actionSelected) && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <JobsEventsTable
                showFiltersList={false}
                showSearchBar
                setToastAlert={setToastAlert}
                filters={currentJob?.jobSlug ? { jobs: currentJob.jobSlug } : {}}
                title="Interviews for Job"
                subtitle={`${currentJob?.title} at ${
                  isCompany
                    ? currentJob.companyName
                    : `${`${currentJob?.venueSlug
                        ?.substring(0, 1)
                        .toUpperCase()}${currentJob?.venueSlug?.substring(1)}`}`
                }`}
              />
            </Grid>
          </>
        )}
        {["jobapplicants", "joball"].includes(actionSelected) && (
          <Grid item xs={12} sm={12} md={12}>
            <JobsApplicantsTable
              type="Applicants"
              showFiltersList
              showSearchBar={false}
              jobSlug={currentJob?.jobSlug}
              venueSlug={currentJob?.venueSlug}
              filters={
                currentJob?.jobSlug
                  ? { "jobs.jobSlug": currentJob.jobSlug, ...applicantsFilters }
                  : { ...applicantsFilters }
              }
              title={`Applicants for Job: ${currentJob?.title} at ${
                isCompany
                  ? currentJob.companyName
                  : `${`${currentJob?.venueSlug
                      ?.substring(0, 1)
                      .toUpperCase()}${currentJob?.venueSlug?.substring(1)}`}`
              }`}
              staffingPoolName={currentJob?.title}
            />
          </Grid>
        )}
        {["jobstaffingpool", "joball"].includes(actionSelected) && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <JobsApplicantsTable
                type="Staffing"
                showFiltersList
                fetchAll
                showSearchBar={false}
                filters={
                  currentJob?.jobSlug
                    ? { "jobs.jobSlug": currentJob.jobSlug, ...applicantsFilters }
                    : {}
                }
                title={`${isCompany ? "Employees" : "Staffing Pool"} for Job: ${
                  currentJob?.title
                } at ${
                  isCompany
                    ? currentJob.companyName
                    : `${`${currentJob?.venueSlug
                        ?.substring(0, 1)
                        .toUpperCase()}${currentJob?.venueSlug?.substring(1)}`}`
                }`}
                staffingPoolName={currentJob?.title}
                venueSlug={currentJob?.venueSlug}
              />
            </Grid>
          </>
        )}
      </Grid>
      {jobPreview && <JobsModal open={open} setOpen={setOpen} jobPreview={jobPreview} />}
      <AddANoteModal
        currentApplicant={currentJob}
        open={notesModalOpen}
        toastAlert={toastAlert}
        setToastAlert={setToastAlert}
        setOpen={setNoteModalOpen}
        saveNote={saveNote}
        types="jobNoteTypes"
      />
    </DashboardLayout>
  );
}

// Setting default values for the props
Jobs.defaultProps = {
  mode: "edit",
};

// Typechecking props
Jobs.propTypes = {
  mode: PropTypes.string,
};
export default Jobs;
