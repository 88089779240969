import { useState, useEffect } from "react";
import { useQuery } from "react-query";

import fetchUsers from "layouts/pages/profile/actions/fetchUsers";

import { Autocomplete, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";
import CustomTextField from "components/Form/CustomTextField";
import MDTypography from "components/MDTypography";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import FormField from "layouts/applications/wizard/components/FormField";
import useCompanyStore from "stores/useCompanyStore";
import { COMPANY_ENTITIES } from "utils/constants/entityOptions";
import useDropdowns from "hooks/useDropdowns";
import { PatternFormat } from "react-number-format";
import { useAppContextController } from "context/AppContext";

const {
  WORKWEEK_ENTITY_NAME,
  OVERTIME_WARNINGS_ENTITY_NAME,
  CLOCKOUT_REMINDERS_ENTITY_NAME,
  APPROVAL_REMINDERS_ENTITY_NAME,
  GEO_PREFERENCE_ENTITY_NAME,
  STATES_ENTITY_NAME,
  PAY_FREQUENCY,
  GROUP_IDS,
  WC_CODES,
} = COMPANY_ENTITIES;

const InputFlexWrapper = ({ children, ...props }) => (
  <FlexWrapper maxWidth={{ lg: "47%", sm: "100%" }} marginBottom={2.5} {...props}>
    {children}
  </FlexWrapper>
);

const CompanyConfiguration = ({
  control,
  sourceComponent,
  setValue,
  trigger,
  errors,
  onBlur = () => {}, // trigger("accountManager")
}) => {
  const { currentCompany } = useCompanyStore((state) => ({
    currentCompany: state.currentCompany,
  }));
  const { company } = useAppContextController();

  const { dropdowns: workweek } = useDropdowns({ entity: WORKWEEK_ENTITY_NAME });
  const { dropdowns: overtimeWarnings } = useDropdowns({ entity: OVERTIME_WARNINGS_ENTITY_NAME });
  const { dropdowns: clockoutReminders } = useDropdowns({ entity: CLOCKOUT_REMINDERS_ENTITY_NAME });
  const { dropdowns: geoPreferences } = useDropdowns({ entity: GEO_PREFERENCE_ENTITY_NAME });
  const { dropdowns: approvalReminders } = useDropdowns({ entity: APPROVAL_REMINDERS_ENTITY_NAME });
  const { dropdowns: states } = useDropdowns({ entity: STATES_ENTITY_NAME });
  const { dropdowns: payFrequencyValues } = useDropdowns({ entity: PAY_FREQUENCY });
  const { dropdowns: groupIds } = useDropdowns({ entity: GROUP_IDS });
  const { dropdowns: wcCodes } = useDropdowns({ entity: WC_CODES });

  const [usersNames, setUsersNames] = useState();

  const options = { fetchAll: true };
  const allOptionsUsers = { ...options, filters: { userType: "Master;Admin" } };
  const { data: users, isLoading: isLoadingUsers } = useQuery(["users", allOptionsUsers], () =>
    fetchUsers(allOptionsUsers)
  );

  useEffect(() => {
    if (users?.data) {
      setUsersNames(
        users?.data
          ?.filter((item) => {
            return ["Active"].includes(item.status) && ["Employee"].includes(item.spStatus);
          })
          .map((user) => ({
            ...user,
            label: `${user.firstName} ${user.lastName}`,
          }))
      );
    }
  }, [users]);

  return (
    <>
      <InputFlexWrapper justifyContent="flex-start" maxWidth="100%" minHeight={38}>
        <MDTypography variant="p" fontSize="18px" color="info" fontWeight="bold">
          {sourceComponent} Configuration
        </MDTypography>
      </InputFlexWrapper>
      <FlexWrapper justifyContent="space-between">
        {sourceComponent === "Company" && (
          <>
            <InputFlexWrapper>
              <Controller
                name="companyUrl"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomTextField
                    label="URL"
                    placeholder="URL"
                    showError
                    errorMessage={error?.message}
                    {...field}
                  />
                )}
              />
            </InputFlexWrapper>
            <InputFlexWrapper>
              <Controller
                name="accountManager"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    key={`autocomplete_recruiter_${currentCompany?.id}`}
                    options={usersNames?.sort((a, b) => (a.firstName > b.firstName ? 1 : -1)) || []}
                    autoSelect
                    name="accountManager"
                    defaultValue={null}
                    value={field.value || ""}
                    onChange={(e, v) => {
                      setValue("accountManager", v?.label ?? null, { shouldDirty: true });
                    }}
                    sx={{ width: "100%" }}
                    onBlur={onBlur}
                    renderInput={(params) => (
                      <>
                        <FormField
                          key={`accountManager_${currentCompany?.id}`}
                          {...params}
                          type="text"
                          label="Account Manager"
                          // InputLabelProps={{ shrink: !isEmpty(getValues("recruiter")) }}
                        />
                        <FormHelperText error>{error?.message}</FormHelperText>
                      </>
                    )}
                  />
                )}
              />
            </InputFlexWrapper>
          </>
        )}

        <InputFlexWrapper>
          <Controller
            name="timeClockSettings.workWeek"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                options={workweek || []}
                name="timeClockSettings.workWeek"
                autoSelect
                defaultValue={null}
                value={field.value || ""}
                onChange={(e, v) => {
                  setValue("timeClockSettings.workWeek", v, { shouldDirty: true });
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    type="text"
                    label="Work Week"
                    showError
                    errorMessage={error?.message}
                  />
                )}
              />
            )}
          />
        </InputFlexWrapper>
        <InputFlexWrapper>
          <Controller
            name="timeClockSettings.overtimeWarning"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                options={overtimeWarnings || []}
                name="timeClockSettings.overtimeWarning"
                autoSelect
                defaultValue={null}
                value={field.value || ""}
                onChange={(e, v) => {
                  setValue("timeClockSettings.overtimeWarning", v, { shouldDirty: true });
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    type="text"
                    label="Overtime Warning"
                    showError
                    errorMessage={error?.message}
                  />
                )}
              />
            )}
          />
        </InputFlexWrapper>
      </FlexWrapper>
      <FlexWrapper justifyContent="space-between">
        <InputFlexWrapper>
          <Controller
            name="timeClockSettings.clockOutReminders"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                options={clockoutReminders || []}
                name="timeClockSettings.clockOutReminders"
                autoSelect
                defaultValue={null}
                value={field.value || ""}
                onChange={(e, v) => {
                  setValue("timeClockSettings.clockOutReminders", v, {
                    shouldDirty: true,
                  });
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    type="text"
                    label="Clock Out Reminders"
                    showError
                    errorMessage={error?.message}
                  />
                )}
              />
            )}
          />
        </InputFlexWrapper>
        <InputFlexWrapper>
          <Controller
            name="timeClockSettings.approvalReminders"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                options={approvalReminders || []}
                name="timeClockSettings.approvalReminders"
                autoSelect
                defaultValue={null}
                value={field.value || ""}
                onChange={(e, v) => {
                  setValue("timeClockSettings.approvalReminders", v, {
                    shouldDirty: true,
                  });
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    type="text"
                    label="Approval Reminders"
                    showError
                    errorMessage={error?.message}
                  />
                )}
              />
            )}
          />
        </InputFlexWrapper>
      </FlexWrapper>
      {company?.companyType === "Company" ? (
        <FlexWrapper justifyContent="space-between">
          <InputFlexWrapper>
            <Controller
              name="geoPreference"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  options={geoPreferences || []}
                  name="geoPreference"
                  autoSelect
                  defaultValue={null}
                  value={field.value || ""}
                  onChange={(e, v) => {
                    setValue("geoPreference", v, {
                      shouldDirty: true,
                    });
                  }}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      type="text"
                      label="Default Job Search Geo Preference"
                      showError
                      errorMessage={error?.message}
                    />
                  )}
                />
              )}
            />
          </InputFlexWrapper>
        </FlexWrapper>
      ) : null}
      <InputFlexWrapper justifyContent="flex-start" maxWidth="100%" minHeight={38}>
        <MDTypography variant="p" fontSize="18px" color="info" fontWeight="bold">
          Employment Configuration
        </MDTypography>
      </InputFlexWrapper>
      <FlexWrapper justifyContent="space-between">
        <InputFlexWrapper>
          <Controller
            name="fein"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <PatternFormat
                format="##-###-####"
                allowEmptyFormatting
                mask="_"
                label="FEIN"
                value={field.value || ""}
                onChange={field.onChange}
                defaultValue=""
                onValueChange={({ value }) => setValue("fein", value, { shouldDirty: true })}
                onBlur={() => trigger("fein")}
                showError
                errorMessage={error?.message}
                customInput={CustomTextField}
              />
            )}
          />
        </InputFlexWrapper>
        <InputFlexWrapper>
          <Controller
            name="wcState"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                options={states || []}
                name="wcState"
                autoSelect
                defaultValue={currentCompany ? currentCompany.state : null}
                value={field.value || ""}
                onChange={(e, v) => {
                  setValue("wcState", v?.toUpperCase() || "", { shouldDirty: true });
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    type="text"
                    label="WC State"
                    showError
                    errorMessage={error?.message}
                  />
                )}
              />
            )}
          />
        </InputFlexWrapper>
      </FlexWrapper>
      <FlexWrapper justifyContent="space-between" mb={-1.5}>
        <InputFlexWrapper>
          <Controller
            name="groupId"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                options={groupIds || []}
                name="groupId"
                autoSelect
                defaultValue={null}
                value={field.value || ""}
                onChange={(e, v) => {
                  setValue("groupId", v, {
                    shouldDirty: true,
                  });
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    type="text"
                    label="Marketing Group ID"
                    showError
                    errorMessage={error?.message}
                  />
                )}
              />
            )}
          />
        </InputFlexWrapper>
        <InputFlexWrapper>
          <Controller
            name="wcCode"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                options={wcCodes || []}
                name="wcCode"
                autoSelect
                defaultValue={null}
                value={field.value || ""}
                onChange={(e, v) => {
                  setValue("wcCode", v, {
                    shouldDirty: true,
                  });
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    type="text"
                    label="WC Code"
                    showError
                    errorMessage={error?.message}
                  />
                )}
              />
            )}
          />
        </InputFlexWrapper>
        <InputFlexWrapper>
          <Controller
            name="payFrequency"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                options={payFrequencyValues || []}
                name="payFrequency"
                autoSelect
                defaultValue={null}
                getOptionLabel={(option) => option.label || option}
                value={
                  field.value
                    ? payFrequencyValues?.find((option) => option.value === field.value)
                    : null
                }
                onChange={(e, v) => {
                  setValue("payFrequency", v.value, {
                    shouldDirty: true,
                  });
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    type="text"
                    label="Pay Frequency"
                    showError
                    errorMessage={error?.message}
                  />
                )}
              />
            )}
          />
        </InputFlexWrapper>
      </FlexWrapper>
    </>
  );
};

export default CompanyConfiguration;
